import React, { useContext } from "react";
import { Play, Star } from "./ui/svgs";
import { DataContext, LanguageContext } from "../App";
import { fileUrl } from "../Tools/APIs";

function OurCourses() {
  const language = useContext(LanguageContext);
  const data = useContext(DataContext);
  const courses = data.courses.data;

  return (
    <div className="w-full py-4 px-4 md:px-8 lg:px-12 flex items-center justify-center">
      <div className="flex flex-col text-center justify-center gap-8 max-sm:w-72r items-center">
        <h4 className="text-[#154FBC] text-2xl md:text-4xl lg:text-5xl font-bold">
          {language === "english" ? "Our courses" : "أبرز الدورات"}
        </h4>
        <p className="text-gray-600 px-1 text-md md:text-xl">
          {language === "english"
            ? "Experience a new way of learning through our library of world-class university courses"
            : "استمتع بتجربة جديدة للتعلم من خلال مكتبتنا التي تضم دورات  عالية المستوى"}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 justify-items-center items-center gap-2">
          {courses.map((course) => (
            <Course
              title={course.name}
              image={fileUrl + course.image}
              description={course.description}
              sepcial={course.is_special}
              lessons={course.lessons_count}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Course({ image, title, description, sepcial, lessons }) {
  return (
    <div className="group w-full h-full max-md:w-[90%] flex flex-col justify-between p-2 lg:p-4 text-[#154FBC] rounded-xl scale-95 md:hover:scale-100 md:hover:bg-gradient-to-b from-[#127EE1] to-[#1550BD] transition-all text-center ">
      <div className="space-y-3">
        <div className="relative rounded-xl">
          <img src={image} className="rounded-xl aspect-[3/2] w-full mx-auto" />
        </div>
        <h6 className=" text-xl md:text-2xl lg:text-3xl text-[#154FBC] md:group-hover:text-white font-bold">
          {title}
        </h6>
        <p className=" max-md:text-sm text-gray-700 md:group-hover:text-gray-200 font-semibold">
          {description}
        </p>
      </div>
      <Lessons sepcial={sepcial} lessons={lessons} />
    </div>
  );
}

function Lessons({ sepcial, lessons }) {
  return (
    <div className="flex text-sm justify-between">
      <div className="flex text-white items-center gap-1">
        <div className="">
          <Play />
        </div>
        {lessons && (
          <p className="font-bold text-[#0B036B] md:group-hover:text-gray-300">
            {lessons} درس
          </p>
        )}
      </div>
      {sepcial && (
        <div className="bg-[#154FBC] md:group-hover:bg-white text-white md:group-hover:text-[#154FBC] text-xs flex items-center gap-1 rounded-full py-1 px-2">
          <Star />
        </div>
      )}
    </div>
  );
}

export default OurCourses;
