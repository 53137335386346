import React, { useContext } from "react";
import { LanguageContext, DataContext } from "../App";
import SectionsSlider from "./SectionsSlider";

function Sections() {
  const language = useContext(LanguageContext);
  const data = useContext(DataContext);

  return (
    <div className="lg:px-12 py-4 flex flex-col gap-8 justify-center items-center">
      <h4 className="text-[#154FBC] text-2xl md:text-4xl lg:text-5xl font-bold">
        {"الأقسام"}
      </h4>
      <p className="text-gray-600 text-lg md:text-xl">{data.sections.header}</p>
      <div className="w-full ">
        <SectionsSlider />
      </div>
    </div>
  );
}

export default Sections;
