import React, { useContext, useEffect } from "react";
import { Mail, Phone } from "./ui/svgs";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { LanguageContext } from "../App";
import { useState } from "react";
import { usePOST } from "../Tools/APIs";
import { toast } from "sonner";

function ContactUs() {
  const language = useContext(LanguageContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const { handleChangeInput, handleSubmit, loading, dataPlayer } = usePOST(
    formData,
    setFormData
  );

  useEffect(() => {
    if (loading) toast.loading("يتم الإرسال", { id: "loading" });
  }, [loading]);

  useEffect(() => {
    if (dataPlayer) {
      toast.dismiss("loading");
      if (dataPlayer.data.code === 200) {
        toast.success("تم الإرسال بنجاح");
      } else {
        toast.error("حدث خطأ بالإرسال");
      }
    }
  }, [dataPlayer]);

  return (
    <div className="space-y-6 px-4 md:px-12 w-full md:py-12">
      <p className="text-[#1458C3] text-3xl md:text-4xl lg:text-5xl text-center font-bold">
        {language === "english" ? "Contact Us" : "تواصل معنا"}
      </p>
      <div className="flex max-md:flex-col w-full items-center gap-5">
        <div className="p-3 md:p-8  md:w-1/3 flex flex-col gap-4 items-center">
          <p className="flex gap-4">
            <Phone />{" "}
            <span className="text-black font-bold">+963 963 155 238</span>
          </p>
          <p className="flex gap-4">
            <Mail />{" "}
            <span className="text-black font-bold">
              IdeaCodeReality.ICR@gmail.com
            </span>
          </p>
        </div>
        <form className="space-y-5 p-2 md:p-8 w-full flex-1">
          <Input
            onChange={(e) => handleChangeInput(e)}
            name="name"
            className="focus:border-[#1458C3] text-gray-700 focus:ring-[#1458C3]"
            required
            type="text"
            placeholder={language === "english" ? "Name" : "الإسم"}
          />
          <Input
            onChange={(e) => handleChangeInput(e)}
            name="email"
            className="focus:border-[#1458C3] text-gray-700 focus:ring-[#1458C3]"
            required
            type="email"
            placeholder={language === "english" ? "Email" : "الإيميل"}
          />
          <Input
            onChange={(e) => handleChangeInput(e)}
            name="phone"
            className="focus:border-[#1458C3] text-gray-700 focus:ring-[#1458C3]"
            required
            type="text"
            placeholder={language === "english" ? "Phone number" : "الرقم"}
          />
          <Textarea
            onChange={(e) => handleChangeInput(e)}
            name="message"
            className="focus:border-[#1458C3] text-gray-700 focus:ring-[#1458C3]"
            placeholder={language === "english" ? "Message" : "الرسالة"}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit("contact-messages", false, true, false);
            }}
            className="text-white bg-[#146CD3] hover:bg-blue-700 w-full"
          >
            {language === "english" ? "Send" : "إرسال"}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
