import React, { useContext } from "react";
import { Courses, Graduate, Hours } from "./ui/svgs";
import { DataContext, LanguageContext } from "../App";

function Accomplishments() {
  const language = useContext(LanguageContext);
  const data = useContext(DataContext);
  const dl = "data.hero.hours_count.toString();";
  return (
    <div className="absolute bottom-14 md:bottom-10 left-0 z-20 w-full overflow-clip flex justify-center">
      <div className="flex justify-between w-full max-w-4xl px-1 sm:px-6 lg:px-8 gap-2 sm:gap-12 items-center text-[#154FBC]">
        <div className="flex-1 md:mx-2 py-2 md:p-4 flex flex-col justify-center items-center border-[#154FBC] border-[3px] rounded-tr-3xl rounded-bl-3xl bg-white">
          <Hours />
          <p
            dir="ltr"
            className="font-bold tracking-tight md:text-lg break-all text-wrap line-clamp-1"
          >
            {data.hero.hours_count.toString()}
          </p>
          <p className="max-md:text-xs tracking-tighter">
            {language === "english" ? "Hours Content" : "عدد الساعات"}
          </p>
        </div>
        <div className="flex-1 md:mx-2 py-2 md:p-4 flex flex-col justify-center items-center border-[#154FBC] border-[3px] rounded-tr-3xl rounded-bl-3xl bg-white">
          <Graduate />
          <p
            dir="ltr"
            className="font-bold tracking-tight md:text-lg break-all text-wrap line-clamp-1"
          >
            {data.hero.students_count.toString()}
          </p>
          <p className="max-md:text-xs tracking-tighter">
            {language === "english" ? "Student" : "الطلاب"}
          </p>
        </div>
        <div className="flex-1 md:mx-2 py-2 md:p-4 flex flex-col justify-center items-center border-[#154FBC] border-[3px] rounded-tr-3xl rounded-bl-3xl bg-white">
          <Courses />
          <p
            dir="ltr"
            className="font-bold tracking-tight md:text-lg break-all text-wrap line-clamp-1"
          >
            {data.hero.courses_count.toString()}
          </p>
          <p className="max-md:text-xs tracking-tighter">
            {language === "english" ? "Courses" : "الدورات"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Accomplishments;
